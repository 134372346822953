// Type
export enum TasksUrlsEnum {
  // BE enums,
  Agreement = 1,
  LegalTraining = 2,
  I9 = 3,
  BackgroundCheck = 4,
  WorkdayAccount = 5,
  W9 = 6, // Tax Documents
  DirectDeposit = 7,
  Licensing = 8,
  ProfilePhoto = 9,
  Apparels = 10,
  RequestDevice = 11,
  Housing = 12,
  W4 = 13,
  DriverLicence = 14,

    // FE routes
  backgroundCheck = BackgroundCheck,
  directDeposit = DirectDeposit,
  i9form = I9,
  legalTrainings = LegalTraining,
  agreement = Agreement,
  taxDocument = W9,
  workdayAccount = WorkdayAccount,
  licensing = Licensing,
  profilePhoto = ProfilePhoto,
  apparels = Apparels,
  requestDevice = RequestDevice,
  housing = Housing,
  w4 = W4,
  driverLicense = DriverLicence,
}

// Status
export enum OnboardingTaskStatus {
  Blocked = 0,
  Incomplete = 1,
  InProgress = 2,
  Completed = 3,
  InitiationError = 4
}

// ProgressState
export enum OnboardingTaskProgressState {
  RecruitInProgress = 0,
  ManagerInProgress = 1,
  ServiceInProgress = 2
}

export const tasksExample = [
  {
    id: 8006,
    name: 'Agreement',
    abbreviation: 'A',
    visible: true,
    type: 1,
    status: 3,
    progressState: 0
  },
  {
    id: 8007,
    name: 'Background Check',
    abbreviation: 'BC',
    visible: true,
    type: 4,
    status: 3,
    progressState: 0
  },
  {
    id: 8008,
    name: 'Employment Eligibility Check',
    abbreviation: 'I9',
    visible: true,
    type: 3,
    status: 1,
    progressState: 0
  },
  {
    id: 8010,
    name: 'Required Training',
    abbreviation: 'LT',
    visible: true,
    type: 2,
    status: 1,
    progressState: 0
  },
  {
    id: 8009,
    name: 'Create Account in Workday',
    abbreviation: 'WD',
    visible: false,
    type: 5,
    status: 3,
    progressState: 0
  },
  {
    id: 8011,
    name: 'Tax Forms',
    abbreviation: 'TD',
    visible: true,
    type: 6,
    status: 0,
    progressState: 0
  },
  {
    id: 8012,
    name: 'Direct Deposit',
    abbreviation: 'DD',
    visible: true,
    type: 7,
    status: 0,
    progressState: 0
  },
  {
    id: 8301,
    name: 'Licensing',
    abbreviation: 'LF',
    visible: false,
    type: 8,
    status: 0,
    progressState: 0,
  },
  {
    id: 8014,
    name: 'Profile Photo',
    abbreviation: 'PIC',
    visible: true,
    type: 9,
    status: 0,
    progressState: 0
  },
  {
    id: 11111,
    name: 'Driver license',
    abbreviation: 'DL',
    visible: true,
    type: 14,
    status: 1,
    progressState: 0
  },
  {
    id: 8015,
    name: 'Device Request',
    abbreviation: 'D',
    visible: false,
    type: 11,
    status: 0,
    progressState: 0
  },
  {
    id: 8016,
    name: 'Apparel Request',
    abbreviation: 'AP',
    visible: true,
    type: 10,
    status: 0,
    progressState: 0
  },
  {
    id: 8017,
    name: 'Housing Request',
    abbreviation: 'H',
    visible: false,
    type: 12,
    status: 0,
    progressState: 0
  }
];

export const taskGroupsExample = [
  {
    name: 'Onboard',
    tasks: tasksExample.slice(0, 5),
  },
  {
    name: 'Get Ready to Sell',
    tasks: tasksExample.slice(5, 10),
  },
  {
    name: 'Look Like a Pro',
    tasks: tasksExample.slice(10)
  }
];
